import React, { useMemo } from 'react';
import app from 'firebase';

import { Notification } from '../../Notification';
import { TrackingTimeline } from '../../TrackingTimeline/TrackingTimeline';
import moment, { Moment } from 'moment';
import { getEstimatedProcessingTime } from '../../../helpers/getEstimatedProcessingTime';
import { addBusinessDays } from '../../../helpers/addBusinessDays';
import { localeMapping } from '../../../contexts/AppTextsContext/localeMapping';
import {
  BaseRmaRequest,
  TrackingCheckpoint,
  TrackingStatus,
} from '../../../../../../functions/src/shared';
import { interpolateAppText, useAppTexts } from '../../../hooks/useAppTexts';
import { useLoadedFirebaseData } from '../../../firebase/hooks';

interface IProps {
  rmaRequest: BaseRmaRequest<app.firestore.Timestamp>;
}

const RmaStatusSection = ({ rmaRequest }: IProps) => {
  const firebase = useLoadedFirebaseData();
  const appTexts = useAppTexts();
  const { publicConfig: appThemeData } = firebase;
  const { tracking, rmaIsPaymentPending, rmaIsCompleted, rmaName } = rmaRequest;

  const trackingLastUpdate = tracking ? moment(tracking.lastUpdate.toDate()) : null;
  const trackingLastUpdateFormatted = useFormattedDate(trackingLastUpdate, appThemeData.language);
  const trackingStatus = tracking ? tracking.status : null;

  const expectedProcessingTime = getEstimatedProcessingTime(firebase);
  const checkpointsSorted = useCheckpointsSorted(tracking?.checkpoints);
  const expectedArrivalDate = useExpectedArrivalMoment(checkpointsSorted, expectedProcessingTime);
  const expectedArrivalDateFormatted = useFormattedDate(expectedArrivalDate, appThemeData.language);

  return (
    <div>
      <Notification
        status="info"
        message={interpolateAppText(appTexts.itemsList.returnAlreadySubmitted, {
          orderName: rmaName,
        })}
      >
        <br />

        <TrackingTimeline
          rmaIsCompleted={rmaIsCompleted}
          currentStatus={trackingStatus}
          hasAftershipTracking={Boolean(tracking)}
        />

        {tracking && (
          <>
            <br />
            {expectedArrivalDateFormatted && (
              <p>
                <span>{appTexts.itemsList.expectedArrivalDate}: </span>
                <span className="rma-info-data">{expectedArrivalDateFormatted}</span>
              </p>
            )}
            <p>
              <span>{appTexts.itemsList.lastUpdate}: </span>
              <span className="rma-info-data">{trackingLastUpdateFormatted}</span>
            </p>
          </>
        )}
      </Notification>

      {rmaIsPaymentPending && (
        <Notification
          status="warning"
          message={
            <>
              <p className="has-text-weight-semibold">{appTexts.itemsList.paymentPending.title}</p>
              <p>
                {interpolateAppText(appTexts.itemsList.paymentPending.message, {
                  email: appThemeData.supportEmail,
                })}
              </p>
            </>
          }
        />
      )}
    </div>
  );
};

/**
 * Gets the checkpoints of the last webhook call sorted ascending by timestamp
 */
const useCheckpointsSorted = (checkpoints: TrackingCheckpoint[] | undefined) => {
  return useMemo(() => {
    if (!checkpoints) {
      return null;
    }

    const checkpointsSorted = [...checkpoints].sort((checkpoint1, checkpoint2) => {
      const date1 = new Date(checkpoint1.checkpoint_time);
      const date2 = new Date(checkpoint2.checkpoint_time);

      return date1.getTime() - date2.getTime();
    });

    return checkpointsSorted;
  }, [checkpoints]);
};

const useExpectedArrivalMoment = (
  checkpointsSorted: TrackingCheckpoint[] | null,
  expectedProcessingTimeBusinessDays?: number,
) => {
  return useMemo(() => {
    if (!checkpointsSorted || expectedProcessingTimeBusinessDays === undefined) {
      return null;
    }

    const firstCheckpointDroppedOff = checkpointsSorted.find((checkpoint) =>
      [TrackingStatus.DroppedOff, TrackingStatus.InTransit].includes(checkpoint.tag),
    );

    if (firstCheckpointDroppedOff === undefined) {
      return null;
    }

    const checkpointDate = new Date(firstCheckpointDroppedOff.checkpoint_time);
    const expectedProcessedDate = addBusinessDays(
      checkpointDate,
      expectedProcessingTimeBusinessDays,
    );

    return moment(expectedProcessedDate);
  }, [checkpointsSorted, expectedProcessingTimeBusinessDays]);
};

const useFormattedDate = (momentOrNull: Moment | null, locale: string) => {
  if (momentOrNull === null) {
    return null;
  }

  return momentOrNull.locale(localeMapping(locale)).format('LL');
};

export default RmaStatusSection;
