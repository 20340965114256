import React, { useMemo, useState } from 'react';

import { Modal } from '../Modal';
import { NoImageFound } from '../NoImageFound';
import PickAction from './Steps/PickAction';
import ExchangeVariant from './Steps/ExchangeVariant';
import PickByTag from './Steps/PickByTag';
import ExchangeCollection from './Steps/ExchangeCollection';
import ExchangeAdditionalVariant from './Steps/ExchangeAdditionalVariant';
import PickReason from './Steps/PickReason';
import AdditionalComment from './Steps/AdditionalComment';
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat';
import { resizeImage } from '../../helpers/resizeImage';
import { useAppTexts } from '../../hooks/useAppTexts';
import {
  ExchangeWizardItem,
  findPossibleNeighboursSorted,
  FullWizardItem,
  WizardAction,
} from '../../contexts/WizardContext/WizardContext';
import {
  Filter,
  QuickSelect,
  Reason,
  TProductData,
  TVariantData,
  wildcardToRegex,
} from '../../../../../functions/src/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuickSelectPicker } from './Steps/QuickSelectPicker';
import { formatProductOptions } from '../../helpers/formatProductOptions';
import { useLoadedFirebaseData } from '../../firebase/hooks';
import { getInventoryQuantityByLocationId } from '../../helpers/getInventoryByLocation';

export type WizardStep =
  | 'pickAction'
  | 'pickExchangeVariantComplete'
  | 'pickExchangeVariantPart1'
  | 'pickExchangeVariantPart2'
  | 'quickSelect'
  | 'reasons'
  | 'additionalReasons'
  | 'additionalComment';

interface Props {
  itemData: FullWizardItem | null;
  setWizardData: (data: FullWizardItem, action: WizardAction) => void;
  showWizard: boolean;
  closeWizard: () => void;
}

type StepOutput = Partial<{
  filter: Filter;
  exchangeProduct: TProductData;
  exchangeFor: ExchangeWizardItem;
  reason: Reason;
  additionalReason: Reason;
  additionalComment: string;
}>;

const ItemWizard = ({ itemData, setWizardData, showWizard, closeWizard }: Props) => {
  const {
    order,
    config: {
      reasons,
      clientFlow,
      lineItemDiscountsProvider,
      inventoryThreshold,
      useOverallInventory,
      selectedInventoryLocations,
    },
    publicConfig: { language },
  } = useLoadedFirebaseData();
  const appTexts = useAppTexts();
  const [stepOutputs, setStepOutputs] = useState<StepOutput>({});
  const [navigationStack, setNavigationStack] = useState<WizardStep[]>([]);
  const currentStep = useMemo(
    () =>
      navigationStack[navigationStack.length - 1] ??
      (clientFlow === 'v2' ? 'reasons' : 'pickAction'),
    [navigationStack, clientFlow],
  );
  const currentAction = useMemo(() => {
    if (!stepOutputs.filter) {
      return null;
    }

    if (stepOutputs.filter.filterType === 'return') {
      return 'return';
    }

    return 'exchange';
  }, [stepOutputs]);

  const { mainImage, stepTitle, subTitleData } = useMemo((): {
    mainImage: { src: string | null; alt: string } | null;
    stepTitle: string | null;
    subTitleData: {
      variantOptions: TProductData['options'];
      variantTitle: TVariantData['title'] | undefined;
      variantPrice: FullWizardItem['displayOriginalPrice'] | null;
      variantDiscountedPrice: FullWizardItem['displayDiscountedPrice'] | null;
    } | null;
  } => {
    if (!itemData) return { mainImage: null, stepTitle: null, subTitleData: null };

    const flowSpecifickPrePickItemSteps: WizardStep[] =
      clientFlow === 'v2' ? ['reasons', 'additionalReasons', 'additionalComment'] : [];
    const prePickItemSteps: WizardStep[] = [
      ...flowSpecifickPrePickItemSteps,
      'quickSelect',
      'pickAction',
      'pickExchangeVariantComplete',
      'pickExchangeVariantPart1',
    ];

    let stepTitle: string;

    if (['reasons', 'additionalReasons', 'additionalComment'].includes(currentStep)) {
      stepTitle =
        currentAction === 'exchange'
          ? appTexts.itemWizard.reasonsWhyExchange
          : appTexts.itemWizard.reasonsWhyReturn;
    } else {
      stepTitle = appTexts.itemWizard.howMakeItRight;
    }

    if (
      !prePickItemSteps.includes(currentStep) &&
      currentAction === 'exchange' &&
      stepOutputs.exchangeProduct
    ) {
      // if on variants selection page (variants is an array) find variant image
      const variant = stepOutputs.exchangeProduct.variants[0] as TVariantData | undefined;
      let image: string | null | undefined;

      if (
        stepOutputs.exchangeFor?.variant.image &&
        !['pickExchangeVariantPart1', 'pickExchangeVariantPart2'].includes(currentStep)
      ) {
        image = stepOutputs.exchangeFor.variant.image;
      } else {
        image = variant && resizeImage(variant.image, 1200, 1200);
      }

      return {
        mainImage: {
          src: image ?? null,
          alt: stepOutputs.exchangeProduct.title,
        },
        stepTitle,
        subTitleData: {
          variantOptions: stepOutputs.exchangeProduct.options,
          variantTitle: stepOutputs.exchangeFor?.variant.title,
          variantPrice:
            stepOutputs.exchangeFor?.discountedPrice !== undefined
              ? getCurrencyFormat(
                  stepOutputs.exchangeFor.discountedPrice,
                  order?.currency,
                  language,
                ) ?? null
              : null,
          variantDiscountedPrice: null,
        },
      };
    }

    return {
      mainImage: {
        src: resizeImage(itemData.variant.image, 1200, 1200),
        alt: itemData.product.title,
      },
      stepTitle,
      subTitleData: {
        variantOptions: itemData.product.options,
        variantTitle: itemData.variant.title,
        variantPrice: itemData.displayOriginalPrice,
        variantDiscountedPrice: itemData.displayDiscountedPrice,
      },
    };
  }, [itemData, currentStep, order, language, stepOutputs, currentAction, clientFlow, appTexts]);

  const applicableQuickSelects = useMemo(() => {
    if (!itemData || !stepOutputs.reason?.quickSelects) return null;

    return stepOutputs.reason.quickSelects.filter((quickSelect) =>
      isApplicableQuickSelect(
        itemData,
        quickSelect,
        inventoryThreshold || 0,
        useOverallInventory !== false,
        selectedInventoryLocations || [],
      ),
    );
  }, [
    itemData,
    stepOutputs.reason,
    inventoryThreshold,
    useOverallInventory,
    selectedInventoryLocations,
  ]);

  const handleCloseWizard = () => {
    setNavigationStack([]);
    closeWizard();
  };

  const handleStartStepSubmit = (filter: Filter) => {
    const updatedStepOutputs = { ...stepOutputs, filter };
    setStepOutputs(updatedStepOutputs);

    if (filter.filterType === 'return' && clientFlow === 'v2') {
      handleEnd(updatedStepOutputs, 'return');
    } else if (filter.filterType === 'return') {
      // v1
      pushStep('reasons');
    } else if (filter.filterType === 'variant') {
      pushStep('pickExchangeVariantComplete');
    } else {
      pushStep('pickExchangeVariantPart1');
    }
  };

  const pushStep = (step: WizardStep) => {
    const newStack = [...navigationStack];
    newStack.push(step);
    setNavigationStack(newStack);
  };

  const handleBack = () => {
    const newStack = [...navigationStack];
    newStack.pop();
    setNavigationStack(newStack);
  };

  /**
   *
   * @param mostRecentStepOutputs Needs to be passed in to prevent race conditions. Enclosed 'stepOutputs' might be outdated at time of call.
   * @param mostRecentAction Needs to be passed in to prevent race conditions. Enclosed 'currentAction' might be outdated at time of call.
   */
  const handleEnd = (
    mostRecentStepOutputs: StepOutput,
    mostRecentAction: 'return' | 'exchange' | null,
  ) => {
    if (!itemData) {
      throw new Error('Item data is not defined');
    }

    if (!mostRecentAction || !mostRecentStepOutputs.reason) {
      throw new Error('Not all required steps are defined');
    }

    const baseActionData: Omit<WizardAction, 'action'> = {
      initialItem: itemData,
      reasonIds: [mostRecentStepOutputs.reason.id],
      additionalComment: mostRecentStepOutputs.additionalComment,
      additionalReason: mostRecentStepOutputs.additionalReason?.inputFieldLabel,
    };

    if (mostRecentAction === 'return') {
      // send original and collected data
      setWizardData(itemData, {
        action: mostRecentAction,
        ...baseActionData,
      });
    } else if (!mostRecentStepOutputs.exchangeFor) {
      throw new Error('Not all required steps are defined');
    } else {
      setWizardData(itemData, {
        action: mostRecentAction,
        ...baseActionData,
        exchangeFor: mostRecentStepOutputs.exchangeFor,
      });
    }

    handleCloseWizard();
  };

  const subTitleMarkup = subTitleData && (
    <h2 className="item-wizard-content-header-subtitle">
      {subTitleData.variantPrice ? (
        <React.Fragment>
          {itemData &&
            Array.isArray(itemData.product.variants) &&
            itemData.product.variants.length > 1 && (
              <React.Fragment>
                <span>
                  {formatProductOptions(subTitleData.variantOptions)}
                  {subTitleData.variantTitle}
                </span>
                <span className="item-wizard-content-header-subtitle-separator">-</span>
              </React.Fragment>
            )}
          <span>
            {!lineItemDiscountsProvider
              ? subTitleData.variantPrice
              : subTitleData.variantDiscountedPrice}
          </span>
        </React.Fragment>
      ) : (
        <span>{appTexts.itemWizard.chooseVariant}</span>
      )}
    </h2>
  );

  const wizardContentMarkup = itemData && (
    <>
      {currentStep === 'pickAction' && (
        <PickAction onSelect={handleStartStepSubmit} itemData={itemData} />
      )}

      {currentStep === 'pickExchangeVariantComplete' && (
        <ExchangeVariant
          onSubmit={(payload) => {
            const updatedStepOutputs: StepOutput = {
              ...stepOutputs,
              exchangeFor: payload,
            };

            setStepOutputs(updatedStepOutputs);

            if (clientFlow === 'v2') {
              handleEnd(updatedStepOutputs, currentAction);
            } else {
              pushStep('reasons');
            }
          }}
          itemData={itemData}
        />
      )}

      {currentStep === 'pickExchangeVariantPart1' &&
        shouldHave(stepOutputs.filter) &&
        stepOutputs.filter.filterType === 'tag' && (
          <div className="item-wizard-content-body">
            <PickByTag
              mode="pick-product"
              onSubmit={(payload) => {
                setStepOutputs({
                  ...stepOutputs,
                  exchangeProduct: payload,
                });

                pushStep('pickExchangeVariantPart2');
              }}
              tag={stepOutputs.filter.filterValue}
              itemData={itemData}
            />
          </div>
        )}

      {currentStep === 'pickExchangeVariantPart1' &&
        shouldHave(stepOutputs.filter) &&
        stepOutputs.filter.filterType === 'collection' && (
          <ExchangeCollection
            onSelect={(payload) => {
              setStepOutputs({
                ...stepOutputs,
                exchangeProduct: payload,
              });
              pushStep('pickExchangeVariantPart2');
            }}
            collections={stepOutputs.filter.filterValue}
            itemData={itemData}
          />
        )}

      {currentStep === 'pickExchangeVariantPart2' && shouldHave(stepOutputs.exchangeProduct) && (
        <ExchangeAdditionalVariant
          onSubmit={(payload) => {
            const updatedStepOutputs: StepOutput = {
              ...stepOutputs,
              exchangeFor: payload,
            };

            setStepOutputs(updatedStepOutputs);

            if (clientFlow === 'v2') {
              handleEnd(updatedStepOutputs, currentAction);
            } else {
              pushStep('reasons');
            }
          }}
          itemData={itemData}
          product={stepOutputs.exchangeProduct}
        />
      )}

      {currentStep === 'reasons' && (
        <PickReason
          reasons={reasons ?? []}
          onSelect={(reason) => {
            const updatedStepOutputs: StepOutput = {
              ...stepOutputs,
              reason,
            };

            setStepOutputs(updatedStepOutputs);

            if (reason.additionalReasons && reason.additionalReasons.length > 0) {
              pushStep('additionalReasons');
            } else if (reason.isInputField) {
              pushStep('additionalComment');
            } else if (
              clientFlow === 'v2' &&
              reason.quickSelects &&
              hasApplicableQuickSelects(
                itemData,
                reason.quickSelects,
                inventoryThreshold || 0,
                useOverallInventory !== false,
                selectedInventoryLocations || [],
              )
            ) {
              pushStep('quickSelect');
            } else if (clientFlow === 'v2') {
              pushStep('pickAction');
            } else {
              // v1
              handleEnd(updatedStepOutputs, currentAction);
            }
          }}
          isRandomized
        />
      )}

      {currentStep === 'additionalReasons' &&
        shouldHave(stepOutputs.reason) &&
        shouldHave(stepOutputs.reason.additionalReasons) && (
          <PickReason
            reasons={stepOutputs.reason.additionalReasons}
            onSelect={(additionalReason) => {
              const updatedStepOutputs: StepOutput = {
                ...stepOutputs,
                additionalReason,
              };

              setStepOutputs(updatedStepOutputs);

              if (additionalReason.isInputField) {
                pushStep('additionalComment');
              } else if (
                clientFlow === 'v2' &&
                additionalReason.quickSelects &&
                hasApplicableQuickSelects(
                  itemData,
                  additionalReason.quickSelects,
                  inventoryThreshold || 0,
                  useOverallInventory !== false,
                  selectedInventoryLocations || [],
                )
              ) {
                pushStep('quickSelect');
              } else if (clientFlow === 'v2') {
                pushStep('pickAction');
              } else {
                // v1
                handleEnd(updatedStepOutputs, currentAction);
              }
            }}
          />
        )}

      {currentStep === 'additionalComment' && shouldHave(stepOutputs.reason) && (
        <AdditionalComment
          additionalCommentLabel={stepOutputs.reason.inputFieldLabel}
          optional={stepOutputs.reason.inputFieldOptional || false}
          onSubmit={(additionalComment) => {
            const updatedStepOutputs: StepOutput = {
              ...stepOutputs,
              additionalComment,
            };

            setStepOutputs(updatedStepOutputs);

            if (
              clientFlow === 'v2' &&
              stepOutputs.reason?.quickSelects &&
              hasApplicableQuickSelects(
                itemData,
                stepOutputs.reason.quickSelects,
                inventoryThreshold || 0,
                useOverallInventory !== false,
                selectedInventoryLocations || [],
              )
            ) {
              pushStep('quickSelect');
            } else if (clientFlow === 'v2') {
              pushStep('pickAction');
            } else {
              // v1
              handleEnd(updatedStepOutputs, currentAction);
            }
          }}
        />
      )}

      {currentStep === 'quickSelect' &&
        shouldHave(applicableQuickSelects) &&
        applicableQuickSelects.length > 0 && (
          <QuickSelectPicker
            onSelect={(payload) => {
              const updatedStepOutputs: StepOutput = {
                ...stepOutputs,
                exchangeFor: payload,
              };

              setStepOutputs(updatedStepOutputs);

              handleEnd(updatedStepOutputs, 'exchange');
            }}
            onMoreOptions={() => {
              pushStep('pickAction');
            }}
            quickSelects={applicableQuickSelects}
            itemData={itemData}
          />
        )}
    </>
  );

  return (
    <Modal show={showWizard} onClose={handleCloseWizard} customClass="item-wizard-modal">
      <div className="item-wizard">
        <div className="item-wizard-image">
          {mainImage && mainImage.src ? (
            <img src={mainImage.src} alt={mainImage.alt} />
          ) : (
            <NoImageFound />
          )}
        </div>
        <div className="item-wizard-content">
          <div className="item-wizard-content-header">
            <h1 className="item-wizard-content-header-title">{stepTitle}</h1>
            {subTitleMarkup}
          </div>
          {navigationStack.length > 0 && (
            <div className="item-wizard-content-subheader">
              <span className="custom-link" onClick={handleBack}>
                <FontAwesomeIcon icon="chevron-left" />
                <span>{appTexts.generic.goBack}</span>
              </span>
            </div>
          )}
          {wizardContentMarkup}
        </div>
      </div>
    </Modal>
  );
};

function shouldHave<T>(value: T | undefined | null): value is T {
  if (value === undefined) {
    throw new Error('Value is undefined');
  }
  return true;
}

const hasApplicableQuickSelects = (
  item: FullWizardItem,
  quickSelects: QuickSelect[],
  inventoryThreshold: number,
  useOverallInventory: boolean,
  selectedInventoryLocations: { id: number; name: string }[],
): boolean => {
  return quickSelects.some((quickSelect) =>
    isApplicableQuickSelect(
      item,
      quickSelect,
      inventoryThreshold,
      useOverallInventory,
      selectedInventoryLocations,
    ),
  );
};

const isApplicableQuickSelect = (
  item: FullWizardItem,
  quickSelect: QuickSelect,
  inventoryThreshold: number,
  useOverallInventory: boolean,
  selectedInventoryLocations: { id: number; name: string }[],
): boolean => {
  if (quickSelect.type === 'same-tag') {
    const tagRegex = wildcardToRegex(quickSelect.tagWithWildcard);
    return item.product.tags.some((tag) => tagRegex.test(tag));
  } else if (quickSelect.type === 'same-product') {
    const variant = item.product.variants.find((variant) => variant.id === item.variant.id);

    if (!variant) {
      // This should not happen, but let's just continue instead of crashing entire app
      return true;
    }

    if (variant.inventory_policy === 'continue') {
      return true;
    }

    if (useOverallInventory) {
      return variant.inventory_quantity >= inventoryThreshold;
    }

    return (
      getInventoryQuantityByLocationId(
        selectedInventoryLocations.map((location) => location.id),
        variant.inventory_at_location || {},
      ) >= inventoryThreshold
    );
  }

  // quickSelect.type === 'neighbouring-option'
  const option = item.variant.options[quickSelect.optionName];

  if (!option) {
    return false;
  }

  const supportedVariants = findPossibleNeighboursSorted(item, quickSelect);

  return supportedVariants.length > 0;
};

export default ItemWizard;
